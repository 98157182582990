<script lang="ts" setup>
import LineChevronRight from '@docue/docue-ui-v2/icons/LineChevronRight.vue'
import LineChevronLeft from '@docue/docue-ui-v2/icons/LineChevronLeft.vue'

const props = defineProps<{
  currentPage: number
  totalPages: number
}>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()
const prevPage = () => {
  if (props.currentPage > 1)
    emit('update', props.currentPage - 1)
}
const nextPage = () => {
  if (props.currentPage < props.totalPages)
    emit('update', props.currentPage + 1)
}
</script>

<template>
  <div
    class="flex w-max divide-x divide-gray-200 rounded-lg border border-gray-200 bg-white"
  >
    <PaginationButton
      class="rounded-l-lg"
      @click="prevPage"
    >
      <LineChevronLeft class="size-4" />
    </PaginationButton>
    <slot />
    <PaginationButton
      class="rounded-r-lg"
      @click="nextPage"
    >
      <LineChevronRight class="size-4" />
    </PaginationButton>
  </div>
</template>
