<script lang="ts" setup>
const props = defineProps<{
  currentPage: number
  totalPages: number
}>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

const update = (val: number) => {
  const withinBounds = Math.min(props.totalPages, Math.max(1, val))
  if (withinBounds !== props.currentPage)
    emit('update', withinBounds)
}

const shouldTruncate = computed(() => props.totalPages > 7)
</script>

<template>
  <div>
    <div class="hidden sm:block">
      <PaginationTruncated
        v-if="shouldTruncate"
        :current-page="currentPage"
        :total-pages="totalPages"
        @update="update"
      />
      <PaginationFull
        v-else
        :current-page="currentPage"
        :total-pages="totalPages"
        @update="update"
      />
    </div>
    <div class="sm:hidden">
      <PaginationMobile
        :current-page="currentPage"
        :total-pages="totalPages"
        @update="update"
      />
    </div>
  </div>
</template>
