<script lang="ts" setup>
defineProps<{
  currentPage: number
  totalPages: number
}>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

const setPage = (page: number) => emit('update', page)
</script>

<template>
  <PaginationFrame
    :current-page="currentPage"
    :total-pages="totalPages"
    @update="setPage"
  >
    <ul class="mb-0 flex divide-x divide-gray-200">
      <li
        v-for="page in totalPages"
        :key="`pagination-${page}`"
      >
        <PaginationButton
          :is-active="page === currentPage"
          @click="setPage(page)"
        >
          {{ page }}
        </PaginationButton>
      </li>
    </ul>
  </PaginationFrame>
</template>
