<script lang="ts" setup>
defineProps<{
  currentPage: number
  totalPages: number
}>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

const setPage = (page: number) => emit('update', page)

const handleSelect = (e: Event) => setPage(Number((e.target as HTMLSelectElement).value))
</script>

<template>
  <PaginationFrame
    :current-page="currentPage"
    :total-pages="totalPages"
    @update="setPage"
  >
    <div
      class="relative flex h-10 w-20 items-center justify-center gap-2 px-5"
    >
      <select
        :value="currentPage"
        class="grow bg-transparent text-center"
        @change="handleSelect"
      >
        <option
          v-for="pageNum in totalPages"
          :key="pageNum"
        >
          {{ pageNum }}
        </option>
      </select>
    </div>
  </PaginationFrame>
</template>
