<script lang="ts" setup>
const props = defineProps<{
  currentPage: number
  totalPages: number
}>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

const setPage = (page: number) => emit('update', page)

const PAGE_FIRST = ref(1)
const PAGE_SECOND = ref(2)

const pageEntries = computed(() => Array.from({ length: props.totalPages }, (_, i) => i + 1))

const lastPages = computed(() => pageEntries.value.slice((props.totalPages - 2), props.totalPages))

const isSecondToLast = (page: number) => page === lastPages.value[0]
const isLastPage = (page: number) => page === lastPages.value[1]

const currentPagesGroup = computed(() => {
  if (props.currentPage === (props.totalPages - 2)) {
    return pageEntries.value.slice(props.currentPage - 3, props.currentPage)
  }
  else if (props.currentPage === (props.totalPages - 1)) {
    return pageEntries.value.slice(props.currentPage - 3, props.currentPage - 1)
  }
  else if (props.currentPage === 3) {
    return pageEntries.value.slice(props.currentPage - 1, props.currentPage + 2)
  }
  else if (props.currentPage > 3 && props.currentPage < (props.totalPages - 2)) {
    return pageEntries.value.filter(i => !lastPages.value.includes(i))
      .slice(props.currentPage - 2, props.currentPage + 1)
  }

  return []
})

const showSecondToLast = computed(() => {
  const thirdPageFromEnd = lastPages.value[0] - 1
  const pageIsFirstOrSecond = [1, 2].includes(props.currentPage)
  return props.currentPage >= thirdPageFromEnd || pageIsFirstOrSecond
})

const showSecondPage = computed(() => props.currentPage < 5 || isLastPage(props.currentPage))
</script>

<template>
  <PaginationFrame
    :current-page="currentPage"
    :total-pages="totalPages"
    @update="setPage"
  >
    <ul class="mb-0 flex divide-x divide-gray-200">
      <li>
        <PaginationButton
          :is-active="PAGE_FIRST === currentPage"
          @click="setPage(PAGE_FIRST)"
        >
          {{ PAGE_FIRST }}
        </PaginationButton>
      </li>
      <li>
        <PaginationButton
          v-if="showSecondPage"
          :is-active="PAGE_SECOND === currentPage"
          @click="setPage(PAGE_SECOND)"
        >
          {{ PAGE_SECOND }}
        </PaginationButton>
        <PaginationEllipsis v-else />
      </li>
      <li
        v-for="page in currentPagesGroup"
        :key="`pagination-${page}`"
      >
        <PaginationButton
          :is-active="page === currentPage"
          @click="setPage(page)"
        >
          {{ page }}
        </PaginationButton>
      </li>
      <li v-if="!currentPagesGroup.length">
        <PaginationEllipsis />
      </li>
      <li
        v-for="page in lastPages"
        :key="`pagination-${page}`"
      >
        <PaginationButton
          v-if="isLastPage(page)"
          :is-active="page === currentPage"
          @click="setPage(page)"
        >
          {{ page }}
        </PaginationButton>
        <PaginationButton
          v-else-if="isSecondToLast(page) && showSecondToLast"
          :is-active="page === currentPage"
          @click="setPage(page)"
        >
          {{ page }}
        </PaginationButton>
        <PaginationEllipsis v-else />
      </li>
    </ul>
  </PaginationFrame>
</template>
